import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import erobotphase from "../../images/EROBOT-phase.jpg"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Solar2 = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Solar Prize Round 2</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Solar Prize is a $3 million prize competition
                designed to energize U.S. solar manufacturing through a series
                of contests and the development of a diverse and powerful
                support network that leverages national laboratories, energy
                incubators, and other resources across the country.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/solarprizeround2">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">Round 2 Go! Contest Winners</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to our two winners from the Go! Contest! These teams
            will be awarded both $500,000 in cash prizes and program vouchers
            for use at national laboratories and connector facilities.
          </p>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team</th>
                  <th className="tds">location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr class="is-size-5">
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/484/entry/30777"
                      class="has-text-default"
                    >
                      &gt;15kV Power Router (SST) DC coupled solar+storage
                    </a>
                  </td>
                  <td>Resilient Power Systems</td>
                  <td className="tds">Georgetown, TX</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr class="is-size-5">
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/484/entry/30535"
                      class="has-text-default"
                    >
                      Back-contacted silicon modules at the cost of PERC
                    </a>
                  </td>
                  <td>SunFlex Solar</td>
                  <td className="tds">Tempe, AZ</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered">
              <a
                href="https://www.herox.com/SolarPrizeRound2/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View the full Winner Submission on HeroX
                </button>
              </a>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>

        <div className="container">
          <h3>Round 2 Go! Demo Day</h3>
          <hr />
          <p className="pcol-fz">
            The American-Made Solar Prize Round 2 finalist teams worked for
            months preparing to pitch their technology at Go! Demo Day. These 10
            teams have been substantially advancing their solutions from
            proof-of-concept to refined prototypes and finding partners to
            perform pilot tests of the prototypes.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Round 2 Set! Contest Finalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the finalists from the Set! Contest! These
            teams will be awarded both cash prizes and program vouchers for use
            at national laboratories and connector facilities. Finalists and
            semifinalists are eligible to compete in the Go! Contest.
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth set-table">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team</th>
                  <th className="tds">location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25221"
                      class="has-text-light"
                    >
                      &gt;15kV Power Router (SST) DC coupled solar+storage
                    </a>
                  </td>
                  <td>Resilient Power Systems</td>
                  <td className="tds">Georgetown, TX</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25178"
                      class="has-text-light"
                    >
                      Advanced Bifacial Panel
                    </a>
                  </td>
                  <td>Taka Solar</td>
                  <td className="tds">Mountain View, CA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/23649"
                      class="has-text-light"
                    >
                      Asoleyo Architectural Solar
                    </a>
                  </td>
                  <td>Asoleyo</td>
                  <td className="tds">Herndon, VA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25164"
                      class="has-text-light"
                    >
                      Back-contacted silicon modules at the cost of PERC
                    </a>
                  </td>
                  <td>SunFlex Solar</td>
                  <td className="tds">Tempe, AZ</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/24884"
                      class="has-text-light"
                    >
                      Continuous Silicon Wafer Production
                    </a>
                  </td>
                  <td>Integrated Silicon Systems</td>
                  <td className="tds">Pittsburgh, PA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/23540"
                      class="has-text-light"
                    >
                      Dirt Control, Bifacial albedo, Roads replacement
                    </a>
                  </td>
                  <td>Terra Pave</td>
                  <td className="tds">Austin, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25211"
                      class="has-text-light"
                    >
                      Orison Energy Storage Enables Solar
                    </a>
                  </td>
                  <td>Orison</td>
                  <td className="tds">Cody, WY</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25156"
                      class="has-text-light"
                    >
                      Phase-EQ for Distributing Solar in Communities
                    </a>
                  </td>
                  <td>Switched Source</td>
                  <td className="tds">Vestal, NY</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25029"
                      class="has-text-light"
                    >
                      SolPavers-Elegant, Invisible, Low-cost, Safe Solar
                    </a>
                  </td>
                  <td>SolPavers</td>
                  <td className="tds">New Braunfels, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/483/entry/25218"
                      class="has-text-light"
                    >
                      V-Clip: Fast, Secure, Reliable PV Panel Attachment
                    </a>
                  </td>
                  <td>Vespr Solar</td>
                  <td className="tds">Norman, OK</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered">
              <a
                href="https://www.herox.com/GroundbreakingHydro/teams"
                target="_blank"
              >
                <button type="button" className="btns">
                  View the full Finalist Submissions on HeroX
                </button>
              </a>
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
        </div>
        <div className="container">
          <h1 className="mac_h1">Round 2 Ready! Contest Semifinalists</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the semifinalists from the Ready! Contest!
            Twenty teams across 14 states have been identified as eligible to
            compete in the next two rounds of the Prize competition. These teams
            will be awarded a cash prize and are now eligible to advance in the
            Set! and Go! Contests!
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth ready-table">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>team location</th>
                  <th className="tds">subject area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23189"
                      class="has-text-light"
                    >
                      &gt;15kV Power Router (SST) DC coupled solar+storage
                    </a>
                  </td>
                  <td>Georgetown, TX</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23032"
                      class="has-text-light"
                    >
                      Asoleyo Decorative Solar Tiles
                    </a>
                  </td>
                  <td>Herndon, VA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23090"
                      class="has-text-light"
                    >
                      Back-contacted silicon modules at the cost of PERC
                    </a>
                  </td>
                  <td>Tempe, AZ</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23127"
                      class="has-text-light"
                    >
                      Big Opportunities with Micro Solar Manufacturing
                    </a>
                  </td>
                  <td>Ashburn, VA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23135"
                      class="has-text-light"
                    >
                      Claremont Locally Grown Power
                    </a>
                  </td>
                  <td>Claremont, CA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23199"
                      class="has-text-light"
                    >
                      Continuous Silicon Wafer Production
                    </a>
                  </td>
                  <td>Pittsburgh, PA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23115"
                      class="has-text-light"
                    >
                      Cost-effective Commercial Solar
                    </a>
                  </td>
                  <td>Mountain View, CA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23193"
                      class="has-text-light"
                    >
                      Critical Network Infrastructure Support (CNIS)
                    </a>
                  </td>
                  <td>Tucson, AZ</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23083"
                      class="has-text-light"
                    >
                      CSP Trans-Critical Water Desalination
                    </a>
                  </td>
                  <td>Pittsburgh, PA</td>
                  <td className="tds">CSP</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/22979"
                      class="has-text-light"
                    >
                      Dirt Control, BiFacial reflect, Roads replacement
                    </a>
                  </td>
                  <td>Austin, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23140"
                      class="has-text-light"
                    >
                      Optimized Lithium Solar Storage Solution (Moon Shine)
                    </a>
                  </td>
                  <td>Winston-Salem, NC</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23151"
                      class="has-text-light"
                    >
                      Piranha System: Saving 1 Penny/Watt in PV Install
                    </a>
                  </td>
                  <td>Norman, OK</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23191"
                      class="has-text-light"
                    >
                      Power Router for Distributing Solar in Communities
                    </a>
                  </td>
                  <td>Vestal, NY</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23137"
                      class="has-text-light"
                    >
                      Reliable and Low Cost Passive Solar Tracking
                    </a>
                  </td>
                  <td>Oxen Hill, MD</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23175"
                      class="has-text-light"
                    >
                      SIGUE Smart off-grid PV energy management
                    </a>
                  </td>
                  <td>Hudson, OH</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23073"
                      class="has-text-light"
                    >
                      Solar-20
                    </a>
                  </td>
                  <td>Ann Arbor, MI</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23129"
                      class="has-text-light"
                    >
                      SolPavers-Elegant, Invisible, Low-cost, Safe Solar
                    </a>
                  </td>
                  <td>New Braunfels, TX</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23196"
                      class="has-text-light"
                    >
                      Sunlight-Shaping Solar Panels
                    </a>
                  </td>
                  <td>Seattle, WA</td>
                  <td className="tds">Photovoltaics</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23008"
                      class="has-text-light"
                    >
                      The world's only self-installable energy storage
                    </a>
                  </td>
                  <td>Cody, WY</td>
                  <td className="tds">Systems Integration</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/solarprizeround2/round/482/entry/23186"
                      class="has-text-light"
                    >
                      Zitara Battery Analytics
                    </a>
                  </td>
                  <td>Oakland, CA</td>
                  <td className="tds">Systems Integration</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered">
              <a
                href="https://www.herox.com/SolarPrizeRound2/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View the full Finalist Submissions on HeroX
                </button>
              </a>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important dates for Round 2</h1>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Submission opens: March 22, 2019</li>
              <li>
                Initial Ideation Submission Period deadline: July 5, 2019 at
                2:00 PM ET Ideation will stay open for new submissions
                throughout the competition. However, to receive a $1,000
                recognition payment, the submission must be entered prior to
                this date.
              </li>
              <li>
                Ready! Contest submission deadline: July 16, 2019 2:00 PM ET
              </li>
              <li>
                Ready! Contest peer ranking: July 18 - 25, 2019 11:59 AM ET
              </li>
              <li>
                Ready! winners announced and Set! and Go! Contests begin:
                September 2019
              </li>
              <li>Set! Contest winners announced: March 30, 2020</li>
              <li>Go! Contest winners announced: August 2020</li>
            </ul>
            <b className="pcol-fz">
              <b>
                All dates are subject to change including contest openings,
                deadlines, and announcements.
              </b>
            </b>
            <p className="ps">
              Sign up for updates on our{" "}
              <a href="https://www.herox.com/solarprizeround2">
                HeroX challenge page
              </a>
              .
            </p>
          </div>
          <hr />
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility or potential partner?
            Anyone with an innovative idea can help transform the solar industry
            through the American-Made Solar Prize.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/SolarPrizeRound2">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Solar2
